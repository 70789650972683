<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container contact-container">
      <h1 class="text-center">Contact Us</h1>

      <div class="row">
        <div class="col">
          Email <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          - or -
        </div>
      </div>
      <div class="row">
        <div class="col">
          Fill in this form
        </div>
      </div>
      <form id="contact-form" class="form">
        <div class="form-group">
          <label>Name*</label>
          <a-input v-model:value="name"></a-input>
        </div>
        <div class="form-group">
          <label>Email*</label>
          <a-input type="email" v-model:value="email"></a-input>
        </div>
        <div class="form-group">
          <label>Phone</label>
          <a-input type="phone" v-model:value="phone"></a-input>
        </div>
        <div class="form-group">
          <label>Message*</label>
          <a-textarea :rows="5" v-model:value="message"></a-textarea>
        </div>
        <div class="form-group">
          <a-button type="submit" class="btn btn-primary" :loading="loading" :disabled="loading" @click="handleSubmit">{{ loading ? '' : 'Submit' }}</a-button>
        </div>
      </form>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import AButton from 'ant-design-vue/lib/button';
import AInput from 'ant-design-vue/lib/input';
import 'ant-design-vue/lib/input/style/index.css';
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
  components: {
    BaseLayout,
    SubHeader,
    AButton,
    AInput,
    'a-textarea': AInput.TextArea,
  },
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      message: null,
      loading: false
    }
  },
  mounted() {
    document.title = 'Contact Us - Mane Street Market';
    window.scrollTo(0, 0);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      if (!this.isFormValid()) {
        return;
      }
      
      let data = {
        name: this.name,
        email: this.email,
        message: this.message
      };
      if (this.phone) {
        data.phone = this.phone;
      }

      this.loading = true;
      axios.post('/api/submit-contact', data).then(() => {
        Swal.fire({
          title: 'Message Sent',
          text: 'We have received your message and will respond shortly.'
        });
        this.clearForm();
      }).catch(err => {
        if (err?.response?.data?.error?.message) {
          err = err.response.data.error.message;
        } else {
          err = 'An error has occurred. Please try again.';
        }
        Swal.fire({
          title: 'Error sending message',
          text: err
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    clearForm() {
      this.name = null;
      this.email = null;
      this.phone = null;
      this.message = null;
    },
    isFormValid() {
      let errorMsg = null;
      if (!this.name || this.name.length === 0) {
        errorMsg = 'Please fill in a name';
      } else if (!this.email || this.email.length === 0) {
        errorMsg = 'Please fill in an email';
      } else if (!this.message || this.message.length === 0) {
        errorMsg = 'Please enter a message';
      }
      if (errorMsg) {
        Swal.fire({
          title: 'There was an error with your form',
          text: errorMsg
        });
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss">
.contact-container {
  .row {
    margin-top: 0.5rem;

    .col {
      text-align: center;
    }
  }
}

#contact-form {
  max-width: 600px;
  margin: auto;
  padding: 2rem;

  .form-button {
    width: 100%;
  }

  textarea {
    resize: none;
  }

  form-group {
    margin-top: 1rem;
  }

  button {
    margin-left: auto;
    margin-right: 0;
    display: block;
  }
}
</style>
